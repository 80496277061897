export class FormValidator {

    constructor ( config ) {
        this.config = config;
        this.errors = new Map();
        this.groupErrors = [];
    }

    validate ( formData ) {
        this.errors.clear();
        this.groupErrors = [];

        this.validateFields( formData );

        if ( this.config.groupValidations ) {
            this.validateGroups( formData );
        }

        return {
            isValid: this.errors.size === 0 && this.groupErrors.length === 0,
            errors: Array.from( this.errors.keys() ),
            groupErrors: this.groupErrors
        };
    }

    validateFields ( formData ) {
        this.config.sections.forEach( section => {
            section.fieldset.forEach( field => {
                if ( !formData[ field.fieldName ] ) return;

                const value = formData[ field.fieldName ];

                if ( field.required && !value ) {
                    this.addError( field.fieldName, field.errorMessage );
                }

                if ( value && field.minlength && value.length < field.minlength ) {
                    this.addError( field.fieldName, field.errorMessage );
                }

                if ( value && field.maxlength && value.length > field.maxlength ) {
                    this.addError( field.fieldName, field.errorMessage );
                }

                if ( value && field.rule && !new RegExp( field.rule ).test( value ) ) {
                    this.addError( field.fieldName, field.errorMessage );
                }
            } );
        } );
    }

    validateGroups ( formData ) {
        this.config.groupValidations.forEach( group => {
            switch (group.type) {
                case 'atLeastOne':
                    this.validateAtLeastOne( group, formData );
                    break;
                case 'allOrNone':
                    this.validateAllOrNone( group, formData );
                    break;
                case 'dependentFields':
                    this.validateDependentFields( group, formData );
                    break;
            }
        } );
    }

    validateAtLeastOne ( group, formData ) {
        const hasValue = group.fields.some( fieldName =>
                                                formData[ fieldName ] && formData[ fieldName ].toString().trim() !== ''
        );

        if ( !hasValue ) {
            this.addGroupError( group.errorMessage, group.fields );
            group.fields.forEach( fieldName => {
                this.errors.set( fieldName, group.errorMessage );
            } );
        }
    }

    validateAllOrNone ( group, formData ) {
        const filledFields = group.fields.filter( fieldName =>
                                                      formData[ fieldName ] && formData[ fieldName ].toString().trim() !== ''
        );

        if ( filledFields.length > 0 && filledFields.length !== group.fields.length ) {
            this.addGroupError( group.errorMessage, group.fields );
            group.fields.forEach( fieldName => {
                if ( !formData[ fieldName ] ) {
                    this.errors.set( fieldName, group.errorMessage );
                }
            } );
        }
    }

    validateDependentFields ( group, formData ) {
        const mainValue = formData[ group.mainField ];

        if ( mainValue && mainValue.toString().trim() !== '' ) {
            const emptyDependentFields = group.dependentFields.filter( fieldName =>
                                                                           !formData[ fieldName ] || formData[ fieldName ].toString().trim() === ''
            );

            if ( emptyDependentFields.length > 0 ) {
                this.addGroupError( group.errorMessage, [ group.mainField, ... group.dependentFields ] );
                emptyDependentFields.forEach( fieldName => {
                    this.errors.set( fieldName, group.errorMessage );
                } );
            }
        }
    }

    addError ( fieldName, message ) {
        this.errors.set( fieldName, message );
    }

    addGroupError ( message, fields ) {
        this.groupErrors.push( {
                                   message: message,
                                   fields: fields
                               } );
    }
}